@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,
body {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  width: 100vw;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title {
  text-align: center;
  margin: auto;
  font-weight: 500;
}
.buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 600px) {
  .buttons {
    flex-direction: row;
  }
}
.buttons1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttons2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  cursor: pointer;
  border-collapse: collapse;
  margin: auto;
  margin-top: 2rem;
}
.gridBody {
  -webkit-tap-highlight-color: transparent;
}
.row {
  color: inherit;
}
.cellContainer {
  border: 0.05rem solid #69768b;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  padding: 0;
  margin: 0;
  position: relative;
}
.cell {
  width: 98%;
  height: 98%;
}
.cell:hover {
  background-color: #5a6375;
}
.alive {
  background-color: #61afef;
}

@keyframes alive {
  from {
    transform: scale(0);
    border-radius: 100%;
    background-color: #282c34;
  }
  to {
    transform: scale(1);
    background-color: #61afef;
  }
}
@keyframes dead {
  from {
    transform: scale(1);
    background-color: #61afef;
  }
  to {
    transform: scale(0);
    background-color: #282c34;
    border-radius: 100%;
  }
}

